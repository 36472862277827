import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {

    title: string;
    text: string;
    doneBtnTitle = 'system.done';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title;
        this.text = data.text;
        this.doneBtnTitle = data.doneBtnTitle ? data.doneBtnTitle : this.doneBtnTitle;
    }

}
